<template>
  <div class="job">
    <div>
      <Header />
      <div class="top" :style="{backgroundColor:info.backgroundColor}">
        <div class="warp">
          <div class="title">{{info.jobTitle}}</div>
          <div class="explain">{{info.jobDescription}}</div>
          <Button @click="$refs.mail.click()" type="warning" size="large"
            >简历发送至邮箱 hr@icbench.com</Button
          >
          <a
            id="mail"
            ref="mail"
            href="mailto:hr@icbench.com?subject=&body="
            v-show="false"
          ></a>
        </div>
      </div>
      <div class="textbox warp">
        <div class="title">岗位职责</div>
        <ol>
          <li v-for="(item,index) in info.jobResponsibility" :key="index">{{item}}</li>
        </ol>
      </div>
      <div class="textbox warp">
        <div class="title">任职资格</div>
        <ol>
          <li v-for="(item,index) in info.jobQualifications" :key="index">{{item}}</li>
        </ol>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
export default {
  name: "Job",
  props: {
    info: {
      type: Object,
      require: true
    },
  },
};
</script>
<style lang='less' scoped>
.job {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top {
  padding: 40px 0 30px;
  color: #fff;
  letter-spacing: 4px;
  .title {
    font-size: 50px;
    line-height: 50px;
    letter-spacing: 4px;
  }
  .explain {
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 22px;
    text-align: left;
    line-height: 35px;
  }
  .warp {
    padding: 0 15px;
  }
}
.textbox.warp {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 15px;
  width: 100%;
  .title {
    font-size: 20px;
    color: #00479d;
    padding: 20px 0 20px;
  }
  ol {
    padding-left: 15px;
    font-size: 16px;
    li {
      line-height: 30px;
    }
  }
}
</style>